import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AddressChange } from 'features/my-profile/my-activity/components/tracked-item/AddressChange';
import { AddressDetails } from 'features/my-profile/my-activity/components/tracked-item/AddressDetails';
import { CancelButton } from 'features/my-profile/my-activity/components/tracked-item/CancelButton';
import { ContactUserButton } from 'features/my-profile/my-activity/components/tracked-item/ContactUserButton';
import { MeetUpButtons } from 'features/my-profile/my-activity/components/tracked-item/MeetUpButtons';
import { PayNowButton } from 'features/my-profile/my-activity/components/tracked-item/PayNowButton';
import { PrintLabelButton } from 'features/my-profile/my-activity/components/tracked-item/PrintLabelButton';
import { RefundButton } from 'features/my-profile/my-activity/components/tracked-item/RefundButton';
import { TrackingDetails } from 'features/my-profile/my-activity/components/tracked-item/TrackingDetails';
import { useNotification } from 'providers/NotificationProvider';
import { PurchaseDto } from 'services/Order/orderService.dto';

const ShipmentDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  isBuyer: boolean;
  order: PurchaseDto;
  onClick: () => void;
}

export const SellerActions = ({ order, isBuyer, onClick }: Props) => {
  const { t } = useTranslation('myProfile');
  const { showNotification } = useNotification();
  const isShippable = order?.shipmentRequired;

  const invalidRequest = () => {
    showNotification({
      title: t('notifications:invalid-action'),
      type: 'error',
    });
  };

  const handleAddressUpdated = () => {
    onClick();
  };

  return (
    <Container data-testid={'seller-actions'}>
      {isShippable && (
        <ShipmentDetails>
          <AddressChange isBuyer={isBuyer} order={order} onAddressUpdated={handleAddressUpdated} />
          <AddressDetails isBuyer={isBuyer} order={order} />
          <TrackingDetails order={order} invalidRequest={invalidRequest} onClick={onClick} />
        </ShipmentDetails>
      )}
      <ButtonsContainer>
        <PayNowButton isBuyer={isBuyer} order={order} onClick={onClick} />
        <MeetUpButtons isBuyer={isBuyer} order={order} invalidRequest={invalidRequest} onClick={onClick} />
        <PrintLabelButton isBuyer={isBuyer} order={order} invalidRequest={invalidRequest} onClick={onClick} />
        <ContactUserButton isBuyer={isBuyer} order={order} onClick={onClick} />
        <RefundButton isBuyer={isBuyer} order={order} onClick={onClick} />
        <CancelButton isBuyer={isBuyer} order={order} onClick={onClick} />
      </ButtonsContainer>
    </Container>
  );
};
