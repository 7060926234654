import { useLocation, useSearchParams } from 'react-router-dom';

import { ROUTE_CAMPAIGNS } from 'navigation/config/routesConfig';
import { FiltersParams } from 'providers/CampaignFiltersProvider';
import { PageState } from 'providers/filtersUtils';

export const useCampaignsSearchPhrase = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const searchPhrase = location.pathname === ROUTE_CAMPAIGNS ? searchParams.get('searchPhrase') : null;

  const applySearchToFilters = (filters: FiltersParams): FiltersParams => {
    if (searchPhrase) {
      return { ...filters, searchPhrase: searchPhrase, sortType: 'BEST_MATCH' };
    } else {
      return filters;
    }
  };

  const applySearchToPageState = (pageState: PageState) => {
    return !!searchPhrase ? 'Filtered' : pageState;
  };

  return { searchPhrase, applySearchToPageState, applySearchToFilters };
};
