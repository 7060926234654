import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ShowAllButton } from 'components/buttons/ShowAllButton';
import { ItemTiles } from 'components/item/ItemsTiles';
import { H4 } from 'components/typography/Headers';
import { useHomeItems } from 'features/item/hooks/useItems';
import { noContentFilters } from 'providers/HotDealsFiltersProviders';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

interface Props {
  resetFilters: () => void;
}

export const AvailableHotDeals = ({ resetFilters }: Props) => {
  const { t } = useTranslation('item');
  const { state } = useHomeItems(noContentFilters, 'Filtered');

  return (
    <Container>
      <HeaderContainer>
        <H4>{t('hot-deals')}</H4>
        <ShowAllButton onClick={resetFilters} />
      </HeaderContainer>
      <ItemTiles items={state.items} />
    </Container>
  );
};
