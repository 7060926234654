import { useEffect, useState } from 'react';

import { SearchType } from 'services/searchTypes';

const SUGGESTIONS_LENGTH = 5;

export const useSearchBoxData = () => {
  const [recentSearches, setRecentSearches] = useState<{ type: SearchType; text: string }[]>([]);

  useEffect(() => {
    const savedSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
    setRecentSearches(savedSearches);
  }, []);

  const saveSearch = (searchType: SearchType, text: string) => {
    const newSearch = { type: searchType, text };

    const filteredSearches = recentSearches.filter(
      item => !(item.type === newSearch.type && item.text === newSearch.text)
    );

    const updatedSearches = [newSearch, ...filteredSearches].slice(0, SUGGESTIONS_LENGTH);
    setRecentSearches(updatedSearches);

    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
  };

  return {
    recentSearches,
    saveSearch,
  };
};
