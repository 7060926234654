import { useState } from 'react';

import { FiltersContainer, HomeContainer, HomeContentContainer } from 'components/containers/Containers';
import { useResponsive } from 'hooks/useResponsive';

import { HotDealsFilters } from './components/hot-deals/filters/HotDealsFilters';
import { MobileHotDealsFilters } from './components/hot-deals/filters/MobileHotDelasFilters';
import { HotDealsOverview } from './components/hot-deals/HotDealsOverview';
import { ItemsList } from './components/hot-deals/ItemsList';

export const HotDeals = () => {
  const { isTablet } = useResponsive();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const toggleFilters = () => setIsFiltersOpen(!isFiltersOpen);

  if (isFiltersOpen) {
    return <MobileHotDealsFilters toggleFilters={toggleFilters} />;
  }

  return (
    <HomeContainer>
      {!isTablet && (
        <FiltersContainer>
          <HotDealsFilters />
        </FiltersContainer>
      )}
      <HomeContentContainer>
        <HotDealsOverview />
        <ItemsList toggleFilters={toggleFilters} />
      </HomeContentContainer>
    </HomeContainer>
  );
};
