import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Location } from 'components/filters/Location';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { useHotDealsFilters } from 'providers/HotDealsFiltersProviders';

import { filtersConfig } from './filterOptionsConfig';
import { HotDealsCategories } from './HotDealsCategories';
import { PickupAreaFilter } from './PickupAreaFilter';
import { PriceSliderFilter } from './price-filter/PriceSliderFilter';
import { ToggleFilterSection } from './ToggleFilterSection';

const Spacer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.color.lighterGray};
  margin: 12px 0;
`;

export const HotDealsFilters = () => {
  const { t } = useTranslation('item');
  const { hotDealsCategories, itemConditions, priceRange } = useGlobalData();
  const { filters, updateFilters } = useHotDealsFilters();

  const deliveryOptions = filtersConfig.deliveryOptions(updateFilters);
  const itemTypeOptions = filtersConfig.sellingMethodOptions(updateFilters);
  const conditionOptions = filtersConfig.conditionOptions(itemConditions, updateFilters);
  return (
    <>
      <PriceSliderFilter priceRange={priceRange!!} />
      <Spacer />
      <HotDealsCategories
        categories={hotDealsCategories}
        selectedCategory={filters.categoryCode}
        onCategoryChange={categoryCode => updateFilters({ categoryCode })}
      />
      <Spacer />
      <ToggleFilterSection
        title={t('filters.condition')}
        selectedFilters={filters.conditionCode}
        options={conditionOptions}
        isMultiSelect={true}
      />
      <Spacer />
      <ToggleFilterSection
        title={t('filters.item-type.title')}
        selectedFilters={filters.sellingMethod}
        options={itemTypeOptions}
      />
      <Spacer />
      <ToggleFilterSection
        title={t('filters.delivery.title')}
        selectedFilters={filters.deliveryType}
        options={deliveryOptions}
      />
      <Spacer />
      <Location cityName={filters.cityName || ''} onLocationChange={location => updateFilters({ location })} />
      <Spacer />
      <PickupAreaFilter />
    </>
  );
};
