import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ItemTile from 'components/item/ItemTile/ItemTile';
import { ItemWithAuctionDto } from 'services/Item/withAuctionsData';

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 24px;
  padding: 24px 0 24px 0;

  ${theme.mq.desktop} {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  ${theme.mq.phone} {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
`;

interface Props {
  items: ItemWithAuctionDto[];
}
export const HomeItemTileList = ({ items }: Props) => {
  return (
    <TilesContainer>
      {items.map(item => (
        <ItemTile key={item.id} item={item} />
      ))}
    </TilesContainer>
  );
};
