import { createContext, useContext, useEffect, useState } from 'react';

import { campaignService } from 'services/Campaign/campaignService';
import { CampaignCategoryDto } from 'services/Campaign/campaignService.dto';
import { itemService } from 'services/Item/itemService';
import { ItemCategoryDto, ItemConditionDto, PriceRangeDto } from 'services/Item/itemService.dto';
import { paymentService } from 'services/Payment/paymentService';
import { PaymentConfigDto } from 'services/Payment/paymentService.dto';
import { reportService } from 'services/Report/reportService';
import { ReasonDto } from 'services/Report/reportService.dto';
import { userService } from 'services/User/userService';
import { CurrentUserDto } from 'services/User/userService.dto';

import { useAuth } from './AuthProvider';

interface GlobalDataContextType {
  itemConditions: ItemConditionDto[];
  reportReasons: ReasonDto[];
  currentUser: CurrentUserDto | null;
  paymentConfig: PaymentConfigDto | undefined;
  campaignCategories: CampaignCategoryDto[];
  hotDealsCategories: ItemCategoryDto[];
  priceRange: PriceRangeDto | undefined;
}

const GlobalDataContext = createContext<GlobalDataContextType>(null!);

function GlobalDataProvider({ children }: any) {
  const { logged } = useAuth();

  const [itemConditions, setItemConditions] = useState<ItemConditionDto[]>([]);
  const [reportReasons, setReportReasons] = useState<ReasonDto[]>([]);
  const [currentUser, setCurrentUser] = useState<CurrentUserDto | null>(null);
  const [paymentConfig, setPaymentConfig] = useState<PaymentConfigDto | undefined>(undefined);
  const [campaignCategories, setCampaignCategories] = useState<CampaignCategoryDto[]>([]);
  const [hotDealsCategories, setHotDealsCategories] = useState<ItemCategoryDto[]>([]);
  const [priceRange, setPriceRange] = useState<PriceRangeDto | undefined>(undefined);

  useEffect(() => {
    fetchItemConditions();
    fetchReportReasons();
    fetchPaymentConfig();
    fetchCampaignCategories();
    fetchHotDealsCategories();
    fetchPriceRange();
  }, []);

  useEffect(() => {
    if (logged) {
      fetchCurrentUser();
    } else {
      setCurrentUser(null);
    }
  }, [logged]);

  const fetchCurrentUser = () => {
    userService
      .fetchCurrentUser()
      .then(({ data }) => setCurrentUser(data))
      .catch(_ => setCurrentUser(null)); // TODO: how to handle errors
  };

  const fetchItemConditions = () => {
    itemService
      .fetchConditions()
      .then(response => setItemConditions(response.data))
      .catch(e => setItemConditions([]));
  };

  const fetchReportReasons = () => {
    reportService
      .fetchReasons()
      .then(response => setReportReasons(response.data))
      .catch(e => setReportReasons([]));
  };

  const fetchPaymentConfig = () => {
    paymentService
      .fetchClientConfiguration('USD')
      .then(response => setPaymentConfig(response.data))
      .catch(e => setPaymentConfig(undefined));
  };

  const fetchCampaignCategories = () => {
    campaignService
      .fetchCategories()
      .then(response => setCampaignCategories(response.data))
      .catch(e => setCampaignCategories([]));
  };

  const fetchHotDealsCategories = () => {
    itemService
      .fetchCategories()
      .then(response => setHotDealsCategories(response.data))
      .catch(e => setHotDealsCategories([]));
  };

  const fetchPriceRange = () => {
    itemService
      .fetchPriceRange()
      .then(response => setPriceRange(response.data))
      .catch(e => setPriceRange(undefined));
  };

  return (
    <GlobalDataContext.Provider
      value={{
        itemConditions,
        reportReasons,
        currentUser,
        paymentConfig,
        campaignCategories,
        hotDealsCategories,
        priceRange,
      }}>
      {children}
    </GlobalDataContext.Provider>
  );
}

const useGlobalData = () => useContext(GlobalDataContext);

export { GlobalDataProvider, useGlobalData };
