import { useEffect } from 'react';

import { PageState } from 'providers/filtersUtils';
import { SearchMarketplaceItemsParams } from 'services/Search/searchService.dto';
import { useSearchItems } from 'services/Search/useSearchItems';

const FEATURED_ITEMS_COUNT = 5;
const UNFILTERED_PAGE_SIZE = 21;
const FILTERED_PAGE_SIZE = 12;

export const useFeaturedItems = () => useSearchItems({ sortType: 'RELEVANT', page: 0, size: FEATURED_ITEMS_COUNT });

export const useHomeItems = (filters: SearchMarketplaceItemsParams, pageState: PageState) => {
  const pageSize = filters.size ?? (pageState === 'Default' ? UNFILTERED_PAGE_SIZE : FILTERED_PAGE_SIZE);

  const homeItems = useSearchItems({
    sortType: filters.sortType,
    size: pageSize,
    page: 0,
    lat: filters.lat,
    lon: filters.lon,
    nationwideShipping: filters.nationwideShipping,
    localPickup: filters.localPickup,
    categoryCode: filters.categoryCode,
    sellingMethod: filters.sellingMethod,
    conditionCode: filters.conditionCode,
    radius: filters.radius,
    priceTo: filters.priceTo,
    priceFrom: filters.priceFrom,
    searchPhrase: filters.searchPhrase,
    ...(filters.sortType === 'RELEVANT' && pageState === 'Default' && { offset: FEATURED_ITEMS_COUNT }),
  });

  const { refresh } = homeItems;

  useEffect(() => {
    refresh();
  }, [filters]);

  return homeItems;
};
