import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { LoadMoreButton } from 'components/buttons/LoadMoreButton';
import { RedTextButton } from 'components/buttons/RedTextButton';
import { CampaignsTiles } from 'components/campaign/CampaignsTiles';
import { SortingDropdown } from 'components/inputs/SortingDropdown';
import { H4 } from 'components/typography/Headers';
import { Text2 } from 'components/typography/Texts';
import Spinner from 'components/ui-elements/Spinner';
import { useHomeCampaigns } from 'features/campaign/hooks/useCampaigns';
import { useResponsive } from 'hooks/useResponsive';
import { useCampaignsFilters } from 'providers/CampaignFiltersProvider';
import { CampaignSortType } from 'services/sortType';

import { CampaignsNotFound } from './CampaignsNotFound/CampaignsNotFound';
import { ClearCampaingsFiltersPanel } from './filters/ClearCampaingsFiltersPanel';

const Container = styled.div`
  padding: 48px 24px 48px 48px;

  ${theme.mq.tablet} {
    padding: 24px 36px;
  }

  ${theme.mq.phone} {
    padding: 12px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FilterButton = styled(RedTextButton)`
  border: 1px solid ${theme.color.red};
`;

const StyledSpinner = styled(Spinner)`
  height: 1000px;
  padding-top: 60px;
  justify-content: flex-start;
`;

interface Props {
  toggleFilters: () => void;
}

export const CampaignsList = ({ toggleFilters }: Props) => {
  const { t } = useTranslation('campaign');
  const { hasActiveFilters, resetFilters, pageState, filters, updateFilters } = useCampaignsFilters();
  const { state, fetchNextPage } = useHomeCampaigns(filters, pageState);
  const hasCampaigns = state.campaigns.length > 0;
  const { isTablet, isMobile } = useResponsive();

  const sortOptions = [
    { value: 'RELEVANT', label: t('option.relevant') },
    { value: 'RECENT', label: t('option.recent') },
    { value: 'NEAREST', label: t('option.nearest') },
    { value: 'BEST_MATCH', label: t('option.best-match') },
  ];

  return (
    <Container>
      <HeaderContainer>
        <H4>{t('campaigns')}</H4>
        <SortContainer>
          {!isMobile && <Text2>{t('sort-by')}</Text2>}
          <SortingDropdown
            sortType={filters.sortType}
            onSortChange={newSortType => updateFilters({ sortType: newSortType as CampaignSortType })}
            sortOptions={sortOptions}
            variant={'campaign'}
          />
          {isTablet && <FilterButton onClick={toggleFilters}>{t('filters-btn')}</FilterButton>}
        </SortContainer>
      </HeaderContainer>
      {hasActiveFilters && <ClearCampaingsFiltersPanel />}
      {hasCampaigns ? (
        <>
          <CampaignsTiles campaigns={state.campaigns} />
          <LoadMoreButton hasNextPage={state.hasNextPage} loading={state.loading} onClick={fetchNextPage} />
        </>
      ) : !state.loading ? (
        <CampaignsNotFound resetFilters={resetFilters} />
      ) : (
        <StyledSpinner />
      )}
    </Container>
  );
};
