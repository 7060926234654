import { useParams } from 'react-router-dom';

import Carousel from 'components/carousel/Carousel';
import {
  ColumnsContainer,
  Container,
  ContentContainer,
  DetailsColumn,
  FloatingSectionContainer,
  MainColumn,
  Title,
} from 'components/containers/ItemCampaignContainers';
import { renderErrorOrSpinner } from 'components/containers/renderErrorOrSpinner';
import { LimitedText } from 'components/limitedText/LimitedText';
import ActionPanel from 'components/ui-elements/ActionPanel';
import ObjectError from 'components/ui-elements/ObjectError';
import { ObjectNotFound } from 'components/ui-elements/ObjectNotFound';
import Spinner from 'components/ui-elements/Spinner';
import { TrackedItemDetails } from 'features/item/components/TrackedItemDetails';
import { ConversationProvider } from 'providers/ConversationProvider';
import { useModal } from 'providers/ModalProvider';
import { useCampaignData } from 'services/Campaign/useCampaignData';
import { useItemData } from 'services/Item/useItemData';
import { usePurchaseDetails } from 'services/Order/usePurchaseData';

import { TrackedItemOrderDetails } from './components/TrackedItemOrderDetails';
import { usePurchaseAccess } from './hooks/usePurchaseAccess';

export const MyActivityTrackedItemPage = () => {
  const { itemId, orderId } = useParams();
  const isBuyer = !!(itemId && orderId);

  const { content: item, error, loading } = useItemData(Number(itemId));
  const { content: campaign } = useCampaignData(item?.supportedCampaignId);
  const {
    order,
    loading: orderLoading,
    error: orderError,
    refreshData,
  } = usePurchaseDetails(Number(itemId), Number(orderId));

  const { userNotAllowed, isLoading: isLoadingPurchaseAccess } = usePurchaseAccess(
    Number(itemId),
    isBuyer,
    item,
    order,
    orderError
  );

  const { showGetApp } = useModal();

  if (loading || isLoadingPurchaseAccess) return renderErrorOrSpinner(<Spinner displayLabel={true} />);
  if (error || !item || !order || userNotAllowed) {
    if (error?.response?.status === 410 || userNotAllowed) return renderErrorOrSpinner(<ObjectNotFound />);
    else return renderErrorOrSpinner(<ObjectError error={error} />);
  }

  return (
    <ConversationProvider>
      <Container>
        <ContentContainer>
          <FloatingSectionContainer>
            <ColumnsContainer>
              <MainColumn>
                <Carousel
                  photos={item.imageUrlList}
                  isCampaign={false}
                  onHeartClick={() => showGetApp({ appAreaType: 'ITEM', objectId: item.id })}
                />
                <Title data-testid={'item-title'}>{item.title}</Title>
                <LimitedText description={item.description} />
              </MainColumn>
              <DetailsColumn>
                <TrackedItemOrderDetails
                  item={item}
                  isBuyer={isBuyer}
                  order={order!!}
                  loading={orderLoading}
                  refreshData={refreshData}
                />
                <TrackedItemDetails item={item} campaign={campaign} />
                <ActionPanel appArea={'ITEM'} objectId={item.id} />
              </DetailsColumn>
            </ColumnsContainer>
          </FloatingSectionContainer>
        </ContentContainer>
      </Container>
    </ConversationProvider>
  );
};
