import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import binocularsIcon from 'assets/svg/binoculars-icon.svg';
import { H5 } from 'components/typography/Headers';
import { EasyGiving } from 'components/ui-elements/EasyGiving';

import { AvailableCampaigns } from './AvailableCampaigns';

const NoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 300px;
`;

interface Props {
  resetFilters: () => void;
}

export const CampaignsNotFound = ({ resetFilters }: Props) => {
  const { t } = useTranslation('common');
  return (
    <>
      <NoContentContainer>
        <img src={binocularsIcon} alt={'binoculars'} />
        <H5>{t('no-results')}</H5>
      </NoContentContainer>
      <AvailableCampaigns resetFilters={resetFilters} />
      <EasyGiving />
    </>
  );
};
