import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

const CountTickView = styled.div<{
  height: number;
  width: number;
  isFiltered: boolean;
  tickMargin: number;
  isLast: boolean;
}>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  margin-right: ${({ isLast, tickMargin }) => (isLast ? '0' : `${tickMargin}px`)};
  background-color: ${({ isFiltered }) => (isFiltered ? `${theme.color.red}` : `${theme.color.lighterGray}`)};
  margin-top: auto;
  border-radius: 2px;
`;

interface PriceSliderTickProps {
  height: number;
  isFiltered: boolean;
  isLast: boolean;
  width: number;
  tickMargin: number;
}

export const PriceSliderTick = ({ height, isFiltered, isLast, width, tickMargin }: PriceSliderTickProps) => {
  return (
    <CountTickView width={width} tickMargin={tickMargin} isFiltered={isFiltered} height={height} isLast={isLast} />
  );
};
