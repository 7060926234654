import { PropsWithChildren } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import closeIcon from 'assets/svg/close-popup.svg';

const OPACITY_TRANSITION_TIME = 300;
export type PaddingVariant = 'STANDARD' | 'REDUCED';

const WrappedModal = ({ className, ...otherProps }: Modal.Props) => (
  <Modal
    portalClassName={className as string}
    className={'Content'}
    overlayClassName={{ base: 'Overlay', afterOpen: 'OverlayAfterOpen', beforeClose: 'OverlayBeforeClose' }}
    {...otherProps}
  />
);

const StyledModal = styled(WrappedModal)<{ hasBlur?: boolean }>`
  & .Overlay {
    opacity: 0;
    transition: opacity ${OPACITY_TRANSITION_TIME}ms ease-in-out;
    inset: 0;
    position: fixed;
    background-color: ${theme.color.overlay};
    z-index: ${theme.zIndex.modal};
  }

  & .OverlayAfterOpen {
    opacity: 1;
  }

  & .OverlayBeforeClose {
    opacity: 0;
  }

  & .Content {
    position: absolute;
    inset: 0;
    border: 1px solid ${theme.color.borderGray};
    background: ${theme.color.white};
    border-radius: 5px;
    outline: none;
    padding: 0;
    width: fit-content;
    height: fit-content;
    max-height: 90%;
    max-width: 700px;
    min-width: 500px;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);

    ${theme.mq.phone} {
      width: 94%;
      max-height: 96%;
      max-width: none;
      min-width: initial;
    }
  }
`;

const Container = styled.div<{ paddingVariant: PaddingVariant }>`
  width: 100%;
  padding: ${props => (props.paddingVariant === 'REDUCED' ? '40px 40px 5px 40px' : '40px')};
  display: flex;
  justify-content: center;
  text-align: center;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 23px;
  right: 24px;
  cursor: pointer;
`;

const BlurEffect = styled.div`
  position: fixed;
  inset: 0;
  backdrop-filter: blur(5px);
  z-index: ${theme.zIndex.modal - 1};
`;

Modal.setAppElement('body');

export interface Props extends PropsWithChildren {
  open: boolean;
  showCloseButton: boolean;
  onCloseClick: () => void;
  onAfterClose: () => void;
  paddingVariant?: PaddingVariant;
  hasBlur?: boolean;
}

const PopupBase = ({ children, open, showCloseButton, onCloseClick, onAfterClose, paddingVariant, hasBlur }: Props) => {
  return (
    <>
      {hasBlur && <BlurEffect />}
      <StyledModal isOpen={open} closeTimeoutMS={OPACITY_TRANSITION_TIME} onAfterClose={onAfterClose} hasBlur={hasBlur}>
        {showCloseButton && <CloseButton data-testid={'close-popup-button'} src={closeIcon} onClick={onCloseClick} />}
        <Container paddingVariant={paddingVariant || 'STANDARD'}>{children}</Container>
      </StyledModal>
    </>
  );
};

export default PopupBase;
