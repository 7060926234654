import styled from 'styled-components';

import Spinner from 'components/ui-elements/Spinner';
import { useShippingAddress } from 'providers/ShippingAddressProvider';

import { ShippingAddress } from './ShippingAddress';
import { ShippingAddressFormHeader } from './ShippingAddressFormHeader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface Props {
  header?: string;
  onAddressSelected?: (addressId: number) => void;
  activeAddressId?: number;
}

export const ShippingAddressesList = ({ header, onAddressSelected, activeAddressId }: Props) => {
  const { shippingAddresses, isLoading, setIsInsertMode, setDefaultShippingAddress } = useShippingAddress();

  const handleSelected = (id: number) => {
    if (onAddressSelected) onAddressSelected(id);
    else setDefaultShippingAddress(id);
  };

  return (
    <div data-testid={'shipping-addresses-list'}>
      <ShippingAddressFormHeader header={header} onAddNew={() => setIsInsertMode(true)} />
      {isLoading && <Spinner />}
      <Container>
        {!isLoading &&
          shippingAddresses?.map(shippingAddress => (
            <ShippingAddress
              key={shippingAddress.id}
              shippingAddress={shippingAddress}
              isLast={shippingAddresses.length === 1}
              isSelected={onAddressSelected ? activeAddressId === shippingAddress.id : shippingAddress.defaultAddress}
              onSelected={handleSelected}
            />
          ))}
      </Container>
    </div>
  );
};
