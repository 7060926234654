import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import arrowIcon from 'assets/svg/red-right-arrow.svg';

import { RedTextButton } from './RedTextButton';

const Button = styled(RedTextButton)`
  margin-left: 0;
  padding: 0;
`;

const Icon = styled.img`
  padding-left: 12px;
`;

interface Props {
  onClick: () => void;
}

export const ShowAllButton = ({ onClick }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Button onClick={onClick}>
      {t('show-all')} <Icon src={arrowIcon} />
    </Button>
  );
};
