import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { theme } from 'assets/styles/theme';
import toManyMsgIcon from 'assets/svg/circle-messages.svg';
import heartIcon from 'assets/svg/heart.svg';
import manuIcon from 'assets/svg/menu.svg';
import messagesIcon from 'assets/svg/messages.svg';
import { IconWrapper, RowContainer } from 'components/containers/Containers';
import { NavbarText } from 'components/typography/NavbarTexts';
import { Caption } from 'components/typography/Texts';
import { useLoginPopup } from 'features/account';
import { useAuth } from 'providers/AuthProvider';
import { useMessages } from 'providers/MessageProvider';
import { useModal } from 'providers/ModalProvider';

import AccountInfo from './AccountInfo';
import Drawer from './Drawer';
import { GiveoLogo } from './GiveoLogo';
import { Container, InnerContainer } from './headerContainers';
import LanguagePicker from './LanguagePicker';
import { navItems } from './navItems';
import SearchBox from './searchBox/SearchBox';
import { useHeaderVisibility } from './useHeaderVisibility';

const MIN_UNREAD_MESSAGES = 0;
const MAX_UNREAD_MESSAGES = 99;

const NavIcon = styled.img`
  margin-right: 10px;
`;

const NavButtonsContainer = styled(RowContainer)`
  margin: 0 72px;
  min-width: 220px;

  ${theme.mq.huge} {
    margin: 0 24px;
    min-width: 220px;
  }

  ${theme.mq.tablet} {
    min-width: 200px;
  }

  ${theme.mq.phone} {
    display: none;
  }
`;

const NavButton = styled(RowContainer)`
  cursor: pointer;
`;

const SearchBoxDesktopContainer = styled(RowContainer)`
  flex: 1;
  margin: 0 24px;

  ${theme.mq.desktop} {
    display: none;
  }
`;

const SearchBoxMobileContainer = styled(RowContainer)`
  flex: 1;
  display: none;
  margin-top: 12px;

  ${theme.mq.desktop} {
    display: flex;
  }
`;

const IconsWrapper = styled(RowContainer)`
  margin: 0 24px;

  ${theme.mq.huge} {
    margin: 0;
  }
`;

const IconContainer = styled(IconWrapper)`
  margin-right: 24px;
`;

const MenuIcon = styled(IconWrapper)`
  margin-left: 24px;
  display: none;

  ${theme.mq.phone} {
    display: flex;
  }
`;

const NavAccountInfo = styled(AccountInfo)`
  ${theme.mq.huge} {
    margin-left: 24px;
  }
  ${theme.mq.phone} {
    display: none;
  }
`;

const ToManyUnreadMsg = styled(IconWrapper)`
  position: absolute;
`;

const UnreadMsgIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  position: relative;
  cursor: pointer;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const UnreadMsg = styled.div`
  width: 18px;
  height: 18px;
  bottom: 10px;
  left: 15px;
  position: absolute;
  background-color: ${theme.color.red};
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.1s ease-in forwards;
`;

const UnreadMsgText = styled(Caption)`
  color: ${theme.color.white};
  font-weight: bolder;
`;

const DefaultHeader = () => {
  const [isMenuSidebarVisible, setIsMenuSidebarVisible] = useState(false);
  const isNavbarVisible = useHeaderVisibility();
  const { showGetApp } = useModal();
  const navigate = useNavigate();
  const { logged } = useAuth();
  const { showLoginPopup } = useLoginPopup();
  const { unreadMessageCount } = useMessages();

  const displayGetApp = () => {
    showGetApp();
  };

  const onMessageClick = () => {
    if (logged) {
      navigate('/messenger');
    } else {
      showLoginPopup();
    }
  };

  const toggleMenuSidebar = () => setIsMenuSidebarVisible(!isMenuSidebarVisible);

  return (
    <>
      <Container isVisible={isNavbarVisible}>
        <InnerContainer>
          <RowContainer>
            <RowContainer>
              <GiveoLogo />
              <NavButtonsContainer>
                {navItems.map(navItem => (
                  <NavButton key={navItem.name} onClick={() => navigate(navItem.link)}>
                    {navItem.icon && <NavIcon src={navItem.icon} />}
                    <NavbarText>{navItem.name}</NavbarText>
                  </NavButton>
                ))}
              </NavButtonsContainer>
            </RowContainer>
            <SearchBoxDesktopContainer>
              <SearchBox />
            </SearchBoxDesktopContainer>
            <RowContainer>
              <IconsWrapper>
                <IconContainer src={heartIcon} onClick={displayGetApp} />
                <UnreadMsgIconContainer data-testid={'messenger-button'} onClick={onMessageClick}>
                  <IconWrapper src={messagesIcon} />
                  {unreadMessageCount > MIN_UNREAD_MESSAGES && (
                    <UnreadMsg>
                      {unreadMessageCount > MAX_UNREAD_MESSAGES ? (
                        <ToManyUnreadMsg src={toManyMsgIcon} />
                      ) : (
                        <UnreadMsgText>{unreadMessageCount}</UnreadMsgText>
                      )}
                    </UnreadMsg>
                  )}
                </UnreadMsgIconContainer>
                <LanguagePicker />
              </IconsWrapper>
              <NavAccountInfo />
              <MenuIcon src={manuIcon} onClick={toggleMenuSidebar} />
            </RowContainer>
          </RowContainer>
          <SearchBoxMobileContainer>
            <SearchBox />
          </SearchBoxMobileContainer>
        </InnerContainer>
      </Container>
      <Drawer toggleSidebar={toggleMenuSidebar} isOpen={isMenuSidebarVisible} />
    </>
  );
};

export default DefaultHeader;
