import numbro from 'numbro';

numbro.setLanguage('en-US');

export const formatMoneyDecimal = (value: number) => {
  return numbro(value).formatCurrency({ mantissa: 2, thousandSeparated: true });
};

export const formatMoneyInteger = (value: number) => {
  return numbro(value).formatCurrency({ mantissa: 0, thousandSeparated: true });
};

export const formatNullableMoneyInteger = (value?: number) => {
  return formatMoneyInteger(value ?? 0);
};

export const currencySymbol = () => {
  return numbro.languageData().currency.symbol;
};
