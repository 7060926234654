import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { TextButton1 } from 'components/typography/TextButtons';
import { useShippingAddressForm } from 'features/my-profile/shippingAddress/hooks/useShippingAddressForm';
import { useAlertPopup } from 'hooks/useAlertPopup';
import { useShippingAddress } from 'providers/ShippingAddressProvider';
import { UserShippingAddressDto } from 'services/User/userService.dto';

import { ShippingAddressDetails } from './ShippingAddressDetails';
import { ShippingAddressForm } from './ShippingAddressForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.lighterGray};
  border-radius: 5px;
  padding: 20px;
  gap: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const FooterContainer = styled(HeaderContainer)`
  justify-content: flex-start;
`;

const StyledTextButton = styled(TextButton1)`
  background-color: ${theme.color.lighterGray};
`;

interface Props {
  shippingAddress: UserShippingAddressDto;
  isLast: boolean;
  isSelected: boolean;
  onSelected: (id: number) => void;
}

export const ShippingAddress = ({ shippingAddress, isLast, isSelected, onSelected }: Props) => {
  const { t } = useTranslation('myProfile');
  const { showAlertPopup } = useAlertPopup();
  const { isSaving, deleteShippingAddress, editedShippingAddressId, setEditedShippingAddressId } = useShippingAddress();
  const isEditMode = editedShippingAddressId === shippingAddress.id;
  const { values, errors, submitForm, setFieldValue, resetForm, isValid, dirty } =
    useShippingAddressForm(shippingAddress);

  const handleDelete = () => {
    showAlertPopup({
      header: t('shipping-address.deletion-popup.header'),
      description: t('shipping-address.deletion-popup.description'),
      firstButton: {
        label: t('shipping-address.deletion-popup.confirm-btn'),
        onClick: () => deleteShippingAddress(shippingAddress.id),
      },
      secondButton: { label: t('shipping-address.deletion-popup.cancel-btn') },
    });
  };

  const handleCancel = () => {
    setEditedShippingAddressId();
    resetForm();
  };

  return (
    <Container data-testid={'shipping-address'}>
      <HeaderContainer>
        <ShippingAddressDetails
          shippingAddress={shippingAddress}
          isSelected={isSelected}
          onSelected={() => onSelected(shippingAddress.id)}
        />
        {!isEditMode && (
          <StyledTextButton data-testid={'edit-btn'} onClick={() => setEditedShippingAddressId(shippingAddress.id)}>
            {t('common:edit')}
          </StyledTextButton>
        )}
        {isEditMode && !isSaving && (
          <>
            <StyledTextButton data-testid={'cancel-btn'} onClick={handleCancel}>
              {t('common:cancel')}
            </StyledTextButton>
            <StyledTextButton data-testid={'save-btn'} onClick={submitForm} disabled={!dirty || !isValid}>
              {t('common:save')}
            </StyledTextButton>
          </>
        )}
      </HeaderContainer>
      {isEditMode && (
        <ShippingAddressForm values={values} setFieldValue={setFieldValue} errors={errors} isEditMode={true} />
      )}
      {isEditMode && !isSaving && (
        <FooterContainer>
          <StyledTextButton data-testid={'delete-btn'} onClick={handleDelete} disabled={isLast}>
            {t('shipping-address.delete-address')}
          </StyledTextButton>
        </FooterContainer>
      )}
    </Container>
  );
};
