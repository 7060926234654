import { useEffect, useRef, useState } from 'react';
import { Swiper as SwiperClass } from 'swiper/types';

export const useSliderControls = (itemsLength: number) => {
  const swiperRef = useRef<SwiperClass>();
  const [prevEnabled, setPrevEnabled] = useState(true);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [displayButtons, setDisplayButtons] = useState(true);

  const recalculateState = () => {
    if (!swiperRef.current) return;
    setPrevEnabled(swiperRef.current.progress > 0);
    setNextEnabled(swiperRef.current.progress < 1);
    setDisplayButtons(swiperRef.current.slidesPerViewDynamic() < itemsLength);
  };

  useEffect(() => {
    recalculateState();
  }, [itemsLength]);

  const onSwiper = (swiper: SwiperClass) => {
    swiperRef.current = swiper;
    recalculateState();
  };

  const onPrevPress = () => swiperRef.current?.slidePrev();
  const onNextPress = () => swiperRef.current?.slideNext();
  const onBreakpoint = () => recalculateState();

  return { swiperRef, prevEnabled, nextEnabled, displayButtons, onSwiper, onPrevPress, onNextPress, onBreakpoint };
};
