import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ButtonBase from 'components/buttons/ButtonBase';
import { renderErrorOrSpinner } from 'components/containers/renderErrorOrSpinner';
import { H3, H5 } from 'components/typography/Headers';
import Spinner from 'components/ui-elements/Spinner';
import { PageState } from 'providers/filtersUtils';
import { CampaignsState, ItemsState } from 'services/Search/searchService.dto';

import { BreakPointConfigType } from './featuredConfig';
import { FeaturedTileSlider } from './FeaturedTileSlider';
import { FeaturedVariant } from './featuredTypes';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Title = styled(H3)`
  color: ${theme.color.white};
`;

const Description = styled(H5)`
  color: ${theme.color.white};
`;

const Button = styled(ButtonBase)`
  max-width: 260px;
  background-color: ${theme.color.white};
  margin-left: 24px;
  color: ${theme.color.lightBlack};

  ${theme.mq.phone} {
    max-width: 195px;
    margin-left: 0;
  }
`;

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.color.red};
  border-radius: 24px;
  margin: 48px 24px 48px 48px;
  padding: 48px;

  ${theme.mq.tablet} {
    padding: 36px;
    margin: 24px;
  }

  ${theme.mq.phone} {
    gap: 24px;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    padding: 24px;
    margin: 12px;
  }
`;

const FeaturedContainer = styled.div`
  width: 100%;
  padding: 0 24px 0 48px;
  margin: 0 auto;

  ${theme.mq.tablet} {
    padding: 0 36px;
  }

  ${theme.mq.phone} {
    padding: 12px;
  }
`;

interface Props {
  title: string;
  description: string;
  buttonLabel: string;
  onButtonClick: () => void;
  state: CampaignsState | ItemsState;
  variant: FeaturedVariant;
  pageState: PageState;
  breakPointConfig: BreakPointConfigType;
}

export const FeaturedSection = ({
  title,
  description,
  buttonLabel,
  onButtonClick,
  state,
  variant,
  pageState,
  breakPointConfig,
}: Props) => {
  const { t } = useTranslation('campaign');
  const items = variant === 'CAMPAIGN' ? (state as CampaignsState).campaigns : (state as ItemsState).items;

  if (pageState !== 'Default') return null;
  if (state.loading) return renderErrorOrSpinner(<Spinner displayLabel={true} />);
  return (
    <Container>
      <BannerContainer>
        <TextContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextContainer>
        <Button onClick={onButtonClick}>{buttonLabel}</Button>
      </BannerContainer>
      <FeaturedContainer>
        <FeaturedTileSlider
          header={t('featured')}
          items={items}
          variant={variant}
          breakPointConfig={breakPointConfig}
        />
      </FeaturedContainer>
    </Container>
  );
};
