import ItemTile from 'components/item/ItemTile/ItemTile';
import { similarItemsBreakpointsConfig } from 'components/tile-slider/brakpointsConfig';
import { TileSlider } from 'components/tile-slider/TileSlider';
import { ItemWithAuctionDto } from 'services/Item/withAuctionsData';

interface Props {
  header: string;
  items: ItemWithAuctionDto[];
}
const ItemTileSlider = ({ header, items }: Props) => {
  return (
    <TileSlider
      header={header}
      items={items}
      renderItem={item => <ItemTile item={item} />}
      breakPointConfig={similarItemsBreakpointsConfig}
      testIdPrefix={'item'}
    />
  );
};

export default ItemTileSlider;
