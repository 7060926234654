import { useGlobalData } from 'providers/GlobalDataProvider';

type PaymentDataRequest = google.payments.api.PaymentDataRequest;

export const useAuthorizationRequest = (amount: number, currency: string) => {
  const { paymentConfig } = useGlobalData();

  const authorizationRequest: PaymentDataRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    emailRequired: true,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
          billingAddressRequired: true,
          billingAddressParameters: { phoneNumberRequired: true, format: 'FULL' },
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: paymentConfig?.googlePayGateway ?? '',
            gatewayMerchantId: paymentConfig?.googlePayMerchantGatewayId ?? '',
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: paymentConfig?.googlePayMerchantId ?? '',
      merchantName: paymentConfig?.googlePayMerchantDisplayName ?? '',
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: amount.toFixed(2),
      currencyCode: currency,
      countryCode: 'US', // TODO: should be passed from item, see: http://jira.intra.source.com.pl/browse/IGA-11529
    },
    callbackIntents: ['PAYMENT_AUTHORIZATION'],
  };

  return authorizationRequest;
};
