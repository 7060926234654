import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Checkbox } from 'components/inputs/Checkbox';
import { Text1Bold } from 'components/typography/Texts';
import { CampaignCategoryDto } from 'services/Campaign/campaignService.dto';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 250px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), ${theme.color.white});
    pointer-events: none;
  }
`;

const Title = styled(Text1Bold)`
  margin-bottom: 12px;
`;

const CategoryList = styled.ul`
  list-style: none;
  padding: 0 0 24px 0;
  margin: 0;
  overflow-y: auto;
`;

const CategoryItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface Props {
  categories: CampaignCategoryDto[];
  selectedCategories: string[];
  onCategoryChange: (categories: string[]) => void;
}

export const Categories = ({ categories, selectedCategories, onCategoryChange }: Props) => {
  const { t } = useTranslation('campaign');

  const handleCategoryChange = (code: string) => {
    const updatedCategories = selectedCategories.includes(code)
      ? selectedCategories.filter(category => category !== code)
      : [...selectedCategories, code];

    onCategoryChange(updatedCategories);
  };

  return (
    <Container>
      <Title>{t('filters.categories')}</Title>
      <CategoryList>
        {categories.map(category => (
          <CategoryItem key={category.code}>
            <Label key={category.categoryOrder}>
              <Checkbox
                type='checkbox'
                checked={selectedCategories.includes(category.code)}
                onChange={() => handleCategoryChange(category.code)}
              />
              {category.displayName}
            </Label>
          </CategoryItem>
        ))}
      </CategoryList>
    </Container>
  );
};
