import { useNavigate } from 'react-router-dom';

import { SearchType } from 'services/searchTypes';

export const useSearchBoxNavigate = () => {
  const navigate = useNavigate();

  const getTargetPath = (searchType: SearchType, searchPhrase: string): string => {
    const basePath = searchType === 'Campaigns' ? '/campaigns' : '/items';
    return `${basePath}?searchPhrase=${encodeURIComponent(searchPhrase)}`;
  };

  const navigateToTarget = (searchType: SearchType, searchPhrase: string): void => {
    const targetPath = getTargetPath(searchType, searchPhrase);
    navigate(targetPath);
  };

  return {
    navigateToTarget,
  };
};
