import { useMemo } from 'react';
import styled from 'styled-components';

import { useShippingAddress } from 'providers/ShippingAddressProvider';
import { PurchaseAddressDto } from 'services/Order/orderService.dto';

import { NewShippingAddress } from './components/NewShippingAddress';
import { ShippingAddressesList } from './components/ShippingAddressesList';
import { ShippingAddressSuggestions } from './components/suggestions/ShippingAddressSuggestions';
import { areAddressesEqual } from './utils/shippingAddressUtils';

const Container = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

interface Props {
  header?: string;
  onAddressSelected?: (addressId: number) => void;
  orderAddress?: PurchaseAddressDto;
}

export const OrderAddressesModule = ({ header, onAddressSelected, orderAddress }: Props) => {
  const { displayMode, shippingAddresses } = useShippingAddress();

  const activeAddressId = useMemo(() => {
    if (!orderAddress) return undefined;
    return shippingAddresses.find(address => areAddressesEqual(address, orderAddress))?.id;
  }, [shippingAddresses, orderAddress]);

  return (
    <Container>
      {displayMode === 'LIST' && (
        <ShippingAddressesList
          header={header}
          onAddressSelected={onAddressSelected}
          activeAddressId={activeAddressId}
        />
      )}
      {displayMode === 'NEW' && <NewShippingAddress header={header} />}
      {displayMode === 'SUGGESTION' && <ShippingAddressSuggestions header={header} />}
    </Container>
  );
};
