import { useMediaQuery } from 'react-responsive';

import { theme } from 'assets/styles/theme';

export const useResponsive = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${theme.breakpoint.phone}px)` });
  const isDesktop = useMediaQuery({ query: `(max-width: ${theme.breakpoint.desktop}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${theme.breakpoint.tablet}px)` });

  return { isMobile, isDesktop, isTablet };
};
