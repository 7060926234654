import { usePopup } from 'hooks/usePopup';

import LoginPopup from './LoginPopup';

interface Props {
  onLogged?: () => void;
  onClose?: () => void;
}

export const useLoginPopup = () => {
  const { showPopup, hidePopup } = usePopup();
  const showLoginPopup = ({ onLogged, onClose }: Props = {}) => {
    showPopup(<LoginPopup onLogged={onLogged || hidePopup} />, { onClose, hasBlur: true });
  };

  return { showLoginPopup };
};
