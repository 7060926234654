import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { DEFAULT_COUNTRY } from 'assets/constants/countries';
import {
  EXPIRATION_DATE_REGEX,
  formatCardNumber,
  formatExpirationDate,
  isExpirationDateValid,
  MAX_CREDIT_CARD_LENGTH,
  MIN_CREDIT_CARD_LENGTH,
  MIN_PHONE_NUMBER_LENGTH,
  parseExpirationDate,
} from 'features/my-profile/payment/utils/paymentMethodUtils';
import { usePayment } from 'providers/PaymentProvider';
import {
  PaymentMethodDto,
  PaymentMethodRequestDto,
  PaymentMethodUpdateRequestDto,
} from 'services/Payment/paymentService.dto';
import { convertToPhoneDto } from 'utils/phoneFormatter';

export interface PaymentMethodFormData {
  firstName: string;
  lastName: string;
  cardNumber: string;
  expirationDate: string;
  cardCode: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
}

export const useNewPaymentForm = () => {
  const { addPaymentMethod, paymentMethods } = usePayment();

  const isPaymentMethodUnique = (lastFourDigits: string) => {
    return paymentMethods.filter(method => method.cardNumber.slice(-4) === lastFourDigits).length === 0;
  };

  const onSubmit = (data: PaymentMethodFormData) => {
    const addPaymentRequest: PaymentMethodRequestDto = {
      billingAddress: {
        address: data.address.trim(),
        city: data.city.trim(),
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        mobilePhone: convertToPhoneDto(data.phone.trim()),
        postcode: data.zip,
        state: data.state,
        country: data.country,
      },
      creditCard: {
        cardCode: data.cardCode.trim(),
        cardNumber: data.cardNumber.trim(),
        expirationDate: parseExpirationDate(data.expirationDate.trim()),
      },
    };
    addPaymentMethod(addPaymentRequest);
  };

  return usePaymentForm(onSubmit, undefined, isPaymentMethodUnique);
};

export const useUpdatePaymentForm = (paymentMethod: PaymentMethodDto) => {
  const { updatePaymentMethod } = usePayment();

  const onSubmit = (data: PaymentMethodFormData) => {
    const updatePaymentRequest: PaymentMethodUpdateRequestDto = {
      billingAddress: {
        address: data.address.trim(),
        city: data.city.trim(),
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        mobilePhone: convertToPhoneDto(data.phone.trim()),
        postcode: data.zip,
        state: data.state,
        country: data.country,
      },
    };
    updatePaymentMethod(paymentMethod.id, updatePaymentRequest);
  };

  return usePaymentForm(onSubmit, paymentMethod);
};

const usePaymentForm = (
  onSubmit: (data: PaymentMethodFormData) => void,
  paymentMethod?: PaymentMethodDto,
  isPaymentMethodUnique?: (lastFourDigits: string) => boolean
) => {
  const { t } = useTranslation('myProfile');
  const isEditMode = !!paymentMethod;

  const initialValues: PaymentMethodFormData = {
    firstName: paymentMethod?.firstName || '',
    lastName: paymentMethod?.lastName || '',
    cardNumber: paymentMethod?.cardNumber ? formatCardNumber(paymentMethod.cardNumber) : '',
    expirationDate: paymentMethod?.expirationDate ? formatExpirationDate(paymentMethod.expirationDate) : '',
    cardCode: paymentMethod ? '***' : '',
    address: paymentMethod?.address || '',
    city: paymentMethod?.city || '',
    state: paymentMethod?.state || '',
    zip: paymentMethod?.zip || '',
    country: paymentMethod?.country || '',
    phone: paymentMethod?.mobilePhone ? `+${paymentMethod.mobilePhone}` : '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(t('form-inputs.first-name-required'))
      .matches(/^[a-zA-Z0-9\s']+$/, t('form-inputs.not-allowed-chars')),
    lastName: Yup.string()
      .trim()
      .required(t('form-inputs.last-name-required'))
      .matches(/^[a-zA-Z0-9\s']+$/, t('form-inputs.not-allowed-chars')),
    cardNumber: Yup.string()
      .trim()
      .required(t('form-inputs.card-number-required'))
      .min(isEditMode ? 0 : MIN_CREDIT_CARD_LENGTH, t('form-inputs.card-number-too-short'))
      .max(MAX_CREDIT_CARD_LENGTH, t('form-inputs.card-number-too-long'))
      .matches(isEditMode ? /^.*$/ : /^[0-9\s']+$/, t('form-inputs.not-allowed-chars'))
      .test('test uniqueness', t('form-inputs.card-number-not-unique'), cardNumber =>
        isPaymentMethodUnique ? isPaymentMethodUnique(cardNumber?.slice(-4)) : true
      ),
    expirationDate: Yup.string()
      .trim()
      .required(t('form-inputs.expiration-date-required'))
      .ensure()
      .matches(EXPIRATION_DATE_REGEX, t('form-inputs.expiration-date-invalid'))
      .test('test expired', t('form-inputs.expiration-date-expired'), date => isExpirationDateValid(date)),
    cardCode: Yup.string()
      .trim()
      .required(t('form-inputs.card-code-required'))
      .min(3, t('form-inputs.card-code-too-short')),
    address: Yup.string().trim().required(t('form-inputs.address-required')),
    city: Yup.string().trim().required(t('form-inputs.city-required')),
    country: Yup.string().trim().required(t('form-inputs.country-required')),
    state: Yup.string()
      .trim()
      .ensure()
      .when('country', {
        is: (value: string) => value === DEFAULT_COUNTRY?.value,
        then: () => Yup.string().trim().required(t('form-inputs.state-required')),
      }),
    zip: Yup.string().trim().required(t('form-inputs.zip-required')),
    phone: Yup.string()
      .trim()
      .required(t('form-inputs.phone-required'))
      .min(MIN_PHONE_NUMBER_LENGTH, t('form-inputs.phone-too-short')),
  });

  return useFormik({ initialValues, validationSchema, onSubmit });
};
