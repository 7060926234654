import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';

import { useShippingAddressError } from 'features/my-profile/shippingAddress/hooks/useShippingAddressError';
import { useShippingAddressNotification } from 'features/my-profile/shippingAddress/hooks/useShippingAddressNotification';
import { useGlobalError } from 'hooks/useGlobalError';
import { userService } from 'services/User/userService';
import {
  ProposedShippingAddressDto,
  ShippingAddressErrorResponse,
  UserShippingAddressDto,
  UserShippingAddressRequestDto,
} from 'services/User/userService.dto';

type DisplayMode = 'NEW' | 'LIST' | 'SUGGESTION';

interface ShippingAddressContextType {
  shippingAddresses: UserShippingAddressDto[];
  defaultShippingAddress?: UserShippingAddressDto;
  proposedAddress?: ProposedShippingAddressDto;
  submittedAddressRequest?: UserShippingAddressRequestDto;
  initState: () => void;
  isLoading: boolean;
  isSaving: boolean;
  addShippingAddress: (request: UserShippingAddressRequestDto) => void;
  updateShippingAddress: (id: number, request: UserShippingAddressRequestDto) => void;
  deleteShippingAddress: (id: number) => void;
  setDefaultShippingAddress: (id: number) => void;
  editedShippingAddressId?: number;
  setEditedShippingAddressId: (id?: number) => void;
  setIsInsertMode: (value: boolean) => void;
  displayMode: DisplayMode;
}

const ShippingAddressContext = createContext<ShippingAddressContextType>(null!);

const ShippingAddressProvider = ({ children }: PropsWithChildren) => {
  const { unknownError } = useGlobalError();
  const { invalidRequest, addressAlreadyExists } = useShippingAddressError();
  const { shippingAddressAdded, shippingAddressUpdated, shippingAddressDeleted } = useShippingAddressNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editedShippingAddressId, setEditedShippingAddressId] = useState<number>();
  const [isInsertMode, setIsInsertMode] = useState(false);
  const [shippingAddresses, setShippingAddresses] = useState<UserShippingAddressDto[]>([]);
  const [submittedAddressRequest, setSubmittedAddressRequest] = useState<UserShippingAddressRequestDto>();
  const [proposedAddress, setProposedAddress] = useState<ProposedShippingAddressDto>();

  const defaultShippingAddress = shippingAddresses.find(shippingAddress => shippingAddress.defaultAddress);

  const displayMode: DisplayMode = useMemo(() => {
    if (isInsertMode) return 'NEW';
    if (!!proposedAddress && !!submittedAddressRequest) return 'SUGGESTION';
    return 'LIST';
  }, [isInsertMode, proposedAddress]);

  useEffect(() => {
    setIsLoading(true);
    setEditedShippingAddressId(undefined);
    setIsInsertMode(false);

    userService
      .fetchShippingAddresses()
      .then(response => {
        setShippingAddresses(response.data);
        if (response.data.length === 0) setIsInsertMode(true);
      })
      .catch(() => unknownError())
      .finally(() => setIsLoading(false));
  }, []);

  const initState = () => {
    setEditedShippingAddressId(undefined);
    setSubmittedAddressRequest(undefined);
    setProposedAddress(undefined);
  };

  const handleSuccessfulResponse = (data: UserShippingAddressDto[]) => {
    setShippingAddresses(data);
    setIsInsertMode(data.length === 0);
    initState();
  };

  const addShippingAddress = (request: UserShippingAddressRequestDto) => {
    setIsSaving(true);
    setSubmittedAddressRequest(request);
    userService
      .addShippingAddress(request)
      .then(response => handleSuccessfulResponse(response.data))
      .then(shippingAddressAdded)
      .catch(error => handleError(error))
      .finally(() => setIsSaving(false));
  };

  const updateShippingAddress = (id: number, request: UserShippingAddressRequestDto) => {
    setIsSaving(true);
    setSubmittedAddressRequest(request);
    userService
      .updateShippingAddress(id, request)
      .then(response => handleSuccessfulResponse(response.data))
      .then(shippingAddressUpdated)
      .catch(error => handleError(error))
      .finally(() => setIsSaving(false));
  };

  const deleteShippingAddress = (id: number) => {
    setIsSaving(true);
    userService
      .deleteShippingAddress(id)
      .then(response => handleSuccessfulResponse(response.data))
      .then(shippingAddressDeleted)
      .catch(error => handleError(error))
      .finally(() => setIsSaving(false));
  };

  const setDefaultShippingAddress = (id: number) => {
    const address = shippingAddresses.find(shippingAddress => shippingAddress.id === id);
    updateShippingAddress(id, { ...address, defaultAddress: true });
  };

  const handleError = (error: AxiosError<ShippingAddressErrorResponse>) => {
    if (error.response?.status === 400) {
      invalidRequest();
    } else if (error.response?.status === 409) {
      addressAlreadyExists();
    } else if (error.response?.status === 422 && error.response?.data.proposedAddress) {
      setIsInsertMode(false);
      setProposedAddress(error.response?.data.proposedAddress);
    } else {
      unknownError();
    }
  };

  return (
    <ShippingAddressContext.Provider
      value={{
        shippingAddresses,
        defaultShippingAddress,
        proposedAddress,
        submittedAddressRequest,
        isLoading,
        isSaving,
        initState,
        addShippingAddress,
        updateShippingAddress,
        deleteShippingAddress,
        setDefaultShippingAddress,
        editedShippingAddressId,
        setEditedShippingAddressId,
        setIsInsertMode,
        displayMode,
      }}>
      {children}
    </ShippingAddressContext.Provider>
  );
};

const useShippingAddress = () => useContext(ShippingAddressContext);

export { ShippingAddressProvider, useShippingAddress };
