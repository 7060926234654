import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Header } from 'features/my-profile/components/Header';
import { AddressesContainer } from 'features/my-profile/shippingAddress/components/AddressesContainer';
import { OrderAddressesModule } from 'features/my-profile/shippingAddress/OrderAddressesModule';
import { ShippingAddressProvider } from 'providers/ShippingAddressProvider';
import { PurchaseAddressDto } from 'services/Order/orderService.dto';

const Container = styled.div`
  width: 700px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

interface Props {
  onAddressSelected: (addressId: number) => void;
  selectedAddress?: PurchaseAddressDto;
}

export const OrderAddressPopup = ({ onAddressSelected, selectedAddress }: Props) => {
  const { t } = useTranslation('myProfile');

  return (
    <Container data-testid={'my-profile-order-address-popup'}>
      <Header>{t('tracked-item.purchase-address.header')}</Header>
      <ShippingAddressProvider>
        <AddressesContainer>
          <OrderAddressesModule
            header={t('tracked-item.purchase-address.set-header')}
            onAddressSelected={onAddressSelected}
            orderAddress={selectedAddress}
          />
        </AddressesContainer>
      </ShippingAddressProvider>
    </Container>
  );
};
