import i18n from 'assets/translations/config';
import { ItemFilter } from 'services/useMyItems';

export const getNoContentConfig = (onAddClick: () => void, onBuyClick: () => void) => ({
  [ItemFilter.BOUGHT]: {
    text: i18n.t('myProfile:my-activity.no-bought-items'),
    buttonText: i18n.t('myProfile:my-activity.buy-first'),
    onClick: onBuyClick,
  },
  [ItemFilter.SELLING]: {
    text: i18n.t('myProfile:my-activity.no-selling-items'),
    buttonText: i18n.t('myProfile:my-activity.add-first'),
    onClick: onAddClick,
  },
  [ItemFilter.DONATED]: {
    text: i18n.t('myProfile:my-activity.no-donated-items'),
    buttonText: i18n.t('myProfile:my-activity.add-first'),
    onClick: onAddClick,
  },
});
