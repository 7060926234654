import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider } from '@mui/material';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text1Bold } from 'components/typography/Texts';
import { useHotDealsFilters } from 'providers/HotDealsFiltersProviders';

const Container = styled.div`
  width: 100%;
`;

const CustomSlider = styled(Slider)`
  margin-bottom: 15px;

  & .MuiSlider-rail {
    background-color: ${theme.color.darkerGray};
  }

  & .MuiSlider-track {
    border: 1px solid ${theme.color.red};
    background-color: ${theme.color.red};
  }

  & .MuiSlider-thumb {
    background-color: ${theme.color.red};

    &:hover {
      box-shadow: none;
    }

    &::after {
      position: absolute;
      content: '';
      width: 15px;
      height: 15px;
      background-color: white;
    }
  }

  & .Mui-focusVisible {
    box-shadow: none !important;
  }

  & .MuiSlider-valueLabelOpen {
    color: black;
    background-color: transparent;
    font-size: 14px;
    top: 45px;
  }
`;

const INFINITY_THRESHOLD = 201;
const MILES_MIN_VALUE = 1;
const SLIDER_STEP = 1;

export const PickupAreaFilter = () => {
  const { t } = useTranslation('item');
  const { filters, updateFilters } = useHotDealsFilters();
  const [sliderValue, setSliderValue] = useState<number>(INFINITY_THRESHOLD);

  useEffect(() => {
    setSliderValue(filters.radius ?? INFINITY_THRESHOLD);
  }, [filters.radius]);

  const handleSliderChange = (value: number) => {
    if (value >= INFINITY_THRESHOLD) {
      updateFilters({ radius: undefined, deliveryType: 'PickUp', sortType: 'NEAREST' });
    } else {
      updateFilters({ radius: value, deliveryType: 'PickUp', sortType: 'NEAREST' });
    }
  };

  const resolveLabel = (value: number) => {
    if (value >= INFINITY_THRESHOLD) {
      return `∞ ${t('filters.mile', { count: value })}`;
    }
    return `${value} ${t('filters.mile', { count: value })}`;
  };

  return (
    <Container>
      <Text1Bold>{t('filters.pickup-area')}</Text1Bold>
      <CustomSlider
        min={MILES_MIN_VALUE}
        value={sliderValue}
        step={SLIDER_STEP}
        max={INFINITY_THRESHOLD}
        valueLabelDisplay='on'
        valueLabelFormat={value => resolveLabel(value)}
        onChangeCommitted={(event, value) => handleSliderChange(value as number)}
        onChange={(event, value) => setSliderValue(value as number)}
      />
    </Container>
  );
};
