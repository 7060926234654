import { useGlobalData } from 'providers/GlobalDataProvider';

export const useAuthorizationRequest = (amount: number, currency: string) => {
  const { paymentConfig } = useGlobalData();

  const authorizationRequest: ApplePayJS.ApplePayPaymentRequest = {
    countryCode: 'US', // TODO: should be passed from item, see: http://jira.intra.source.com.pl/browse/IGA-11529
    currencyCode: currency,
    total: {
      label: paymentConfig?.applePayMerchantDisplayName ?? '',
      amount: `${amount}`,
      type: 'final',
    },
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: ['amex', 'discover', 'interac', 'jcb', 'privateLabel', 'masterCard', 'visa'],
    requiredBillingContactFields: ['name', 'postalAddress'],
    requiredShippingContactFields: ['email', 'phone'],
  };

  return authorizationRequest;
};
