import i18n from 'i18next';

import hotDealsIcon from 'assets/svg/hot-deals.svg';

export interface NavItemType {
  icon?: string;
  name: string;
  link: string;
}

export const navItems: NavItemType[] = [
  {
    name: i18n.t('common:navbar.campaigns'),
    link: '/campaigns',
  },
  {
    icon: hotDealsIcon,
    name: i18n.t('common:navbar.hot-deals'),
    link: '/items',
  },
];
