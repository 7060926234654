import React from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { currencySymbol, formatMoneyInteger } from 'utils/numberFormatter';

const Input = styled.input`
  width: 100%;
  height: 45px;
  font-family: ${theme.font.family.text};
  font-weight: 400;
  font-size: 40px;
  line-height: 18px;
  color: ${theme.color.black};
  background: ${theme.color.lighterGray};
  border-radius: 5px;
  border: none !important;
  text-align: center;
`;

interface Props {
  value: number;
  onChange: (value: number) => void;
}

export const PriceSliderInput = ({ value, onChange }: Props) => {
  const formattedPrice = value === 0 ? `${currencySymbol()}0` : formatMoneyInteger(value);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = Number(inputValue.replace(/\D/g, ''));
    onChange(numericValue);
  };
  return <Input data-testid={'input'} value={formattedPrice} onChange={handleInputChange} inputMode={'numeric'} />;
};
