import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';

import PopupBase, { PaddingVariant } from 'components/popups/PopupBase';

import { PopupOptions } from './popupTypes';

export interface PopupData {
  id: string;
  content: ReactNode;
  hideRequested: boolean;
  hideClose: boolean;
  onAfterHide?: () => void;
  onClose?: () => void;
  paddingVariant?: PaddingVariant;
  hasBlur?: boolean;
}

interface PopupManagerContextType {
  showPopup: (content: ReactNode, options?: PopupOptions) => string;
  hidePopup: (popupId: string) => void;
  popups: PopupData[];
}

const PopupManagerContext = createContext<PopupManagerContextType>(null!);

const PopupManagerProvider: FC<{ children: ReactNode }> = props => {
  const [popups, setPopups] = useState<PopupData[]>([]);

  const showPopup = (modalContent: ReactNode, options: PopupOptions = {}) => {
    const newPopup: PopupData = {
      id: uuid(),
      content: modalContent,
      hideRequested: false,
      hideClose: !!options.hideClose,
      onAfterHide: options.onAfterHide,
      onClose: options.onClose,
      paddingVariant: options.paddingVariant || 'STANDARD',
      hasBlur: options.hasBlur,
    };

    if (options.keepPopups) {
      setPopups(currentPopups => [...currentPopups, newPopup]);
    } else {
      setPopups(currentPopups => [...currentPopups.map(popup => ({ ...popup, hideRequested: true })), newPopup]);
    }

    return newPopup.id;
  };

  const hidePopup = (id: string) =>
    setPopups(current => current.map(popup => (popup.id === id ? { ...popup, hideRequested: true } : popup)));

  const afterHide = ({ id, onAfterHide }: PopupData) => {
    setPopups(current => current.filter(popup => popup.id !== id));
    onAfterHide?.();
  };

  return (
    <PopupManagerContext.Provider value={{ showPopup, hidePopup, popups }}>
      {props.children}
      {popups.map(popup => (
        <PopupBase
          key={popup.id}
          open={!popup.hideRequested}
          showCloseButton={!popup.hideClose}
          onCloseClick={() => {
            hidePopup(popup.id);
            popup.onClose?.();
          }}
          onAfterClose={() => afterHide(popup)}
          paddingVariant={popup.paddingVariant}
          hasBlur={popup.hasBlur}>
          {popup.content}
        </PopupBase>
      ))}
    </PopupManagerContext.Provider>
  );
};

const usePopupManager = () => useContext(PopupManagerContext);

export { PopupManagerProvider, usePopupManager };
