import { theme } from 'assets/styles/theme';

export interface BreakPointConfigType {
  [width: number]: { slidesPerView: number; spaceBetween: number };
}

export const campaignBreakpointsConfig = ((): BreakPointConfigType => {
  const breakpoints = [];
  breakpoints[theme.breakpoint.min] = { slidesPerView: 2, spaceBetween: 12 };
  breakpoints[theme.breakpoint.phone] = { slidesPerView: 2, spaceBetween: 12 };
  breakpoints[theme.breakpoint.tablet] = { slidesPerView: 3, spaceBetween: 12 };
  breakpoints[theme.breakpoint.desktop] = { slidesPerView: 3, spaceBetween: 12 };
  breakpoints[theme.breakpoint.huge] = { slidesPerView: 3, spaceBetween: 24 };
  return breakpoints;
})();

export const hotDealsBreakpointsConfig = ((): BreakPointConfigType => {
  const breakpoints = [];
  breakpoints[theme.breakpoint.min] = { slidesPerView: 2, spaceBetween: 12 };
  breakpoints[theme.breakpoint.phone] = { slidesPerView: 3, spaceBetween: 12 };
  breakpoints[theme.breakpoint.tablet] = { slidesPerView: 3, spaceBetween: 12 };
  breakpoints[theme.breakpoint.desktop] = { slidesPerView: 4, spaceBetween: 12 };
  breakpoints[theme.breakpoint.huge] = { slidesPerView: 4, spaceBetween: 24 };
  return breakpoints;
})();
