import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import arrowDownIcon from 'assets/svg/arrow-down.svg';
import { DropdownInput } from 'features/my-profile/components/FormContainers';
import { SearchType } from 'services/searchTypes';

const StyledDropdown = styled(DropdownInput)`
  width: 170px;
  padding: 0 20px;

  & .react-select__control {
    cursor: pointer;
    background-color: ${theme.color.lighterGray};
  }

  & .react-select__option {
    z-index: 1000;
    cursor: pointer;
  }

  & .react-select__option--is-selected {
    background-color: ${theme.color.red};
  }

  & .react-select__option--is-focused {
    background-color: rgba(252, 104, 111, 0.2);
  }

  & .react-select__option--is-selected.react-select__option--is-focused {
    background-color: ${theme.color.red};
  }

  & .react-select__indicators {
    display: none;
  }
`;

const Spacer = styled.div`
  height: 30px;
  border-left: 1px solid ${theme.color.borderGray};
`;

const ArrowIcon = styled.img`
  position: absolute;
  right: 10px;
  padding: 10px;
  pointer-events: none;
`;

interface Props {
  searchType: SearchType;
  onTypeChange: (searchType: SearchType) => void;
  onMenuOpen: () => void;
}

export const SearchTypeDropdown = ({ searchType, onTypeChange, onMenuOpen }: Props) => {
  const { t } = useTranslation();

  const searchTypeOptions = [
    { value: 'Items', label: t('navbar.hot-deals') },
    { value: 'Campaigns', label: t('navbar.campaigns') },
  ];
  return (
    <>
      <Spacer />
      <StyledDropdown
        value={searchType}
        options={searchTypeOptions}
        onChange={newSearchType => onTypeChange(newSearchType as SearchType)}
        variant={'UPDATE'}
        onMenuOpen={onMenuOpen}
        useMenuPortal={false}
      />
      <ArrowIcon src={arrowDownIcon} alt='arrow-icon' />
    </>
  );
};
