import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { ItemDto } from 'services/Item/itemService.dto';

import ItemTile from './ItemTile/ItemTile';

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 24px;
  padding: 24px 0 24px 0;

  ${theme.mq.desktop} {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  ${theme.mq.phone} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface Props {
  items: ItemDto[];
}
export const ItemTiles = ({ items }: Props) => {
  return (
    <TilesContainer>
      {items.map(item => (
        <ItemTile key={item.id.toString()} item={item} />
      ))}
    </TilesContainer>
  );
};
