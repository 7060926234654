import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import iphone from 'assets/svg/iphone.svg';
import RedButton from 'components/buttons/RedButton';
import { H1, H4 } from 'components/typography/Headers';
import { useModal } from 'providers/ModalProvider';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${theme.mq.desktop} {
    flex-direction: column-reverse;
  }
`;

const IphoneImg = styled.img`
  height: auto;
  width: 50%;

  ${theme.mq.desktop} {
    width: 70%;
  }
`;

const TextContainer = styled.div`
  max-width: 50%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${theme.mq.huge} {
    padding: 24px;
    gap: 24px;
  }

  ${theme.mq.desktop} {
    max-width: 100%;
    padding-top: 0;
  }
`;

const Description = styled(H4)`
  font-weight: normal;
  white-space: break-spaces;
`;

const Button = styled(RedButton)`
  max-width: 260px;

  ${theme.mq.desktop} {
    max-width: 100%;
  }
`;

export const EasyGiving = () => {
  const { t } = useTranslation('campaign');
  const { showGetApp } = useModal();

  return (
    <Container>
      <TextContainer>
        <H1>{t('easy-giving.title')}</H1>
        <Description>{t('easy-giving.desc')}</Description>
        <Button onClick={() => showGetApp()}>{t('easy-giving.btn')}</Button>
      </TextContainer>
      <IphoneImg src={iphone} />
    </Container>
  );
};
